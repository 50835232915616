<template functional>
	<div>
		<div class="page-section">
			<div class="page-section__inner gr gr--wrap">
				<div class="col col--1@tablet hide show@tablet"></div>
				<div class="col col--12 col--10@tablet">
					<div key="auth-div" class=" page-section__card">
						<h3 class="subtitle subtitle--big page-section__card__subtitle black mb-0">
							Page not found | 404
						</h3>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PageNotFound'
};
</script>
